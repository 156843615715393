import generateBlockLink, { parseQueryString } from '@/services/utils/LinkGenerator';
import { checkIsValidColor, hexToRgbaWithOpacity } from '@/services/utils/helper';
import cls from 'classnames';
import { isObject, map } from 'lodash';
import Link from 'next/link';
import Slider from '../molecules/Slider';
import ProductCardBeauty from '../organisms/ProductCardBeauty';
import ProductCard from '../organisms/Products';
import ProductCardHorizontal from '../organisms/Products/Horizontal';

export function replaceTextPlaceholders(text, params) {
	if (typeof text !== 'string' || !Array.isArray(params)) {
		return text;
	}

	let newText = text;

	params.forEach((replacementObj) => {
		Object.keys(replacementObj).forEach((placeholder) => {
			const value = replacementObj[placeholder];
			newText = newText.replace(placeholder, value);
		});
	});

	return newText;
}
interface Props {
	data?: any;
	key?: any;
	titleParams?: any;
	sl?: any;
}
function hexToRgb(hex) {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return [r, g, b];
}

function calculateOriginalColor(resultColor, alpha) {
	const [resultR, resultG, resultB] = hexToRgb(resultColor);
	const bgR = 255,
		bgG = 255,
		bgB = 255; // White background

	const originalR = Math.round((resultR - (1 - alpha) * bgR) / alpha);
	const originalG = Math.round((resultG - (1 - alpha) * bgG) / alpha);
	const originalB = Math.round((resultB - (1 - alpha) * bgB) / alpha);

	return `rgb(${originalR}, ${originalG}, ${originalB})`;
}
const SideScrollBlock = ({ sl, data, titleParams }: Props) => {
	const {
		block_data,
		block,
		block_color_code,
		block_display_type,
		block_weight,
		block_subtitle,
		block_machine_name,
		block_link_parameter
	} = data;

	const getBlockSize = () => {
		switch (block_display_type) {
			case 'sidescroll_vertical':
				return 6;
			case 'sidescroll_horizontal':
				return 2.6;
			case 'except_add_to_cart_button':
				return 6;
			default:
				return 3;
		}
	};

	const { link } = generateBlockLink(data);
	const newBlockData = isObject(block_data) ? Object.values(block_data) : block_data;

	if (!newBlockData) return null;
	if (newBlockData.length === 0) return null;
	const replacedText = replaceTextPlaceholders(block, titleParams);

	const renderSlides = (block_display_type, newBlockData, i) => {
		switch (block_display_type) {
			case 'sidescroll_vertical':
				return <ProductCard data={newBlockData} key={i} />;
			case 'sidescroll_horizontal':
				return <ProductCardHorizontal data={newBlockData} key={i} />;

			case 'except_add_to_cart_button':
				return <ProductCardBeauty data={newBlockData} key={i} />;
			default:
				return <ProductCard data={newBlockData} key={i} />;
		}
	};

	const SlideComponent = (block_display_type) => {
		switch (block_display_type) {
			case 'sidescroll_vertical':
				return ProductCard;
			case 'sidescroll_horizontal':
				return ProductCardHorizontal;
			case 'except_add_to_cart_button':
				return ProductCardBeauty;
			default:
				return ProductCard;
		}
	};

	const params = parseQueryString(link) || {};
	return (
		<div
			className={cls('py-30')}
			style={{
				backgroundColor: checkIsValidColor(block_color_code)
					? `${hexToRgbaWithOpacity(block_color_code, 0.2)}`
					: 'transparent'
			}}>
			<div
				className='container product-slider'
				style={{
					position: 'relative',
					zIndex: 100 - parseInt(sl)
				}}>
				<div className='d-flex justify-space-between items-center mb-30'>
					<h2
						className='text-30 fw-600 mb-0'
						style={{
							color: block_color_code
						}}>
						{replacedText}
						{block_subtitle && (
							<div
								className='text-18 fw-400 mt-10'
								style={{
									color: block_color_code
								}}>
								{block_subtitle}
							</div>
						)}
					</h2>
					{/* if newBlockData is bigger then getBlockSize() then show view all button */}
					{newBlockData.length > getBlockSize() && (
						<Link href={link}>
							<div
								className='text-20 text-grey900 fw-700 cursor-pointer hover-text-primary'
								style={{
									color: checkIsValidColor(block_color_code) ? block_color_code : ''
								}}>
								see all
							</div>
						</Link>
					)}
				</div>

				<div className='my-10  mt-10 items-center'>
					<Slider
						slidesPerView={getBlockSize()}
						spaceBetween={getBlockSize() === 3 ? 20 : 20}
						shouldFetchData={block_machine_name}
						SlideComponent={SlideComponent(block_display_type)}
						params={params}
						shouldSlidesPerGroup={true}
						// slidesPerGroup={getBlockSize()}
						responsive={{
							'0': {
								slidesPerView: 2
							},
							'640': {
								slidesPerView: 2
							},
							'768': {
								slidesPerView: getBlockSize() === 3 ? 1 : 3
							},
							'1024': {
								slidesPerView: 3,
								spaceBetween: 10
							},
							'1280': {
								slidesPerView: 4,
								spaceBetween: 15
							},
							'1440': {
								slidesPerView: 6
							}
						}}>
						{map(newBlockData, (d, i) => renderSlides(block_display_type, d, i))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default SideScrollBlock;
