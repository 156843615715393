'use client';

import ProductLoader from '@/app/products/ProductLoader';
import Empty from '@/components/atoms/Empty';
import { replaceTextPlaceholders } from '@/components/dynamics/SideScrollBlock';
import InfiniteScrollComponent from '@/components/organisms/InfiniteScrollComponent';
import ProductCard from '@/components/organisms/Products';
import { API_PUBLIC } from '@/services/apis/apiConstrant';
import useDeepCompareMemo from '@/services/hooks/useDeepCompareMemo';
import { replacePlaceholders } from '@/services/utils/LinkGenerator';
import { checkIsValidColor } from '@/services/utils/helper';
import cls from 'classnames';

const AllProducts = ({ data,titleParams }:any) => {
	const { block_styles, block_color_code, block, block_link_parameter, extra } = data;
	const params = replacePlaceholders(block_link_parameter, extra || {});
	const memoizedFilter = useDeepCompareMemo({
		_order: 'pv_allow_sales:desc',
		...params,
		_perPage: 18
	});

	const PLoader = () => (
		<div className='col-md-2 mb-10'>
			<ProductLoader />
		</div>
	);
	const replacedText = replaceTextPlaceholders(block, titleParams);
	return (
		<div>
			<div
				className={cls('py-30 ')}
				style={{
					...block_styles,
					backgroundColor: checkIsValidColor(block_color_code) ? `${block_color_code}` : 'transparent'
				}}>
				<div className='container product-slider'>
					<div className='d-flex justify-space-between items-center mb-30'>
						<h2 className='text-30 text-grey900 fw-600'>{replacedText}</h2>
					</div>

					{/* <div className="mt-10 row p-0"> */}
					{/* {block_data &&
							block_data?.map((d: any, i: number) => (
								<div className="col-md-2 mb-10" key={i}>
									<ProductCard data={d} />
								</div>
							))} */}
					<InfiniteScrollComponent
						endpoint={API_PUBLIC.GET_PRODUCT_FROM_SEARCH}
						method='GET'
						initialFilter={memoizedFilter}
						PAGE_SIZE={18}
						CusomLoader={PLoader}
						uniqueKey={'pv[0].pv_id'}
						EmptyComponent={
							<div className='d-flex justify-center mt-20'>
								<Empty description='No products found' />
							</div>
						}
						className='mt-10 row p-0'>
						{(product, index) => (
							<div className='col-md-2 mb-10' key={index}>
								<ProductCard data={product} />
							</div>
						)}
					</InfiniteScrollComponent>
					{/* </div> */}
				</div>
			</div>
		</div>
	);
};

export default AllProducts;
